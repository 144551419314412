@mixin underlineLink() {
  $thickness: 1px;
  $bottom: 94%;
  $line-color: rgba($color-text, 0.2);
  background-image: linear-gradient($line-color, $line-color), linear-gradient($color-text, $color-text);
  background-position: 0% $bottom, 0% $bottom;
  background-repeat: no-repeat;
  background-size: 100% $thickness, 0 $thickness;
  transition: background 0.6s;
  transition-timing-function: cubic-bezier("easeOutQuart");
  html:not(.is-mobile) &:hover,
  html:not(.is-mobile) a:hover & {
    transition-duration: 0.6s;
    background-size: 100% $thickness, 100% $thickness;
  }
}
