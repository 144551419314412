$page-breakpoint: "sm";

.Page {
  padding: var(--spacer);
  display: flex;
  flex-direction: column;
  @include media-breakpoint-up($page-breakpoint) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    flex-direction: row;
    overflow: auto;
  }
}

.Page-wrapper {
  @include media-breakpoint-up($page-breakpoint) {
    &:first-child {
      flex: 0 0 55%;
    }
    &:last-child {
      flex: 0 0 45%;
    }
    display: flex;
    flex-direction: column;
    &:first-child {
      border-right: 1px solid var(--line-color);
    }
  }
  @include media-breakpoint-up(lg) {
    flex: 0 0 50% !important;
  }
}

.Page-cell {
  border-top: 1px solid var(--line-color);
  padding: var(--spacer) 0;
}

.Page-cell--brand {
  border: none;
  padding-top: 0;
}

.Page-cell--headline {
  @include media-breakpoint-up($page-breakpoint) {
    padding-right: var(--spacer);
    padding-right:10%;
  }
  @include media-breakpoint-up(lg) {
  }
}

.Page-cell--partners {
  p {
    display: inline;
  }
  @include media-breakpoint-up($page-breakpoint) {
    margin-top: auto;
    padding-right: var(--spacer);
    padding-bottom: 0;
  }
  @include media-breakpoint-up(lg) {
    padding-right:25%;
  }
  // border-top: 1px solid var(--line-color);
  // padding-top: var(--spacer);
}

.Page-cell--info {
  @include media-breakpoint-up($page-breakpoint) {
    margin-top: auto;
    padding-left: var(--spacer);
    padding-bottom: 0;
  }
  @include media-breakpoint-up(md) {
    br.separator {
      display: none;
    }
  }
  // border-top: 1px solid var(--line-color);
  // padding-top: var(--spacer);
}
