:root {
  --spacer: 1.5rem;
  --line-color: #{rgba($color-text, 0.3)};
  @include media-breakpoint-up(sm) {
    --line-color: #{rgba($color-text, 0.8)};
    --spacer: #{px2vw(36, 1024)};
  }
  @include media-breakpoint-up(sm) {
    --spacer: #{px2vw(32, 1024)};
  }
  @include media-breakpoint-up(lg) {
    --spacer: #{px2vw(48, 1920)};
  }
}


* {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

::selection {
  color: $color-text;
  background: rgba($color-text, 0.15);
}

html {
  font-family: sans-serif;
  -webkit-text-size-adjust: 100%;
  @include res-prop("font-size", $font-size-root);
  // -webkit-tap-highlight-color: rgba($black, 0);
}

body {
  font-family: $font-family-base;
  @include font-size($font-size-base);
  font-weight: $font-weight-base;
  line-height: $line-height-base;
  color: $body-color;
  background-color: $body-bg;
}

h1 {
  font-size: 1.15rem;
  font-weight: 500;
  @include media-breakpoint-up(sm) {
    font-size: 1.5rem;
    line-height: 1.6;
  }
  @include media-breakpoint-up(lg) {
    font-size: 1.8rem;
    line-height: 1.6;
  }
}

a {
  color: $color-link;
  text-decoration: none;
  pointer-events: auto;
  @include underlineLink();
  &[href^="tel"] {
    @include media-breakpoint-up(sm) {
      pointer-events: none;
      background: none;
    }
  }
}

p {
  margin-bottom: calc(var(--spacer) * 0.5);
  &:last-child {
    margin-bottom: 0;
  }
}

// html,
// body {
//   height: 100%;
//   overflow: hidden;
// }

// html {
//   -webkit-tap-highlight-color: rgba(color("gold-tan"), 0.2);
//   @include font-smoothing("antialiased");
//   @each $breakpoint in map-keys($grid-breakpoints) {
//     @include media-breakpoint-up($breakpoint) {
//       font-size: map-get($font-size-root, $breakpoint);
//     }
//   }
// }

// .menu-is-open #main {
//   pointer-events: none;
// }

// * {
//   outline: none !important;
// }

// img {
//   width: 100%;
// }

// svg {
//   fill: currentColor;
//   vertical-align: middle;
// }

// .container-fluid {
//   @include media-breakpoint-up(xl) {
//     padding-left: 8%;
//     padding-right: 8%;
//   }
// }

// .tz {
//   transform: translateZ(0);
// }

// .page-transitioning * {
//   pointer-events: none !important;
// }

// .placeholder {
//   background-color: $color-image-placeholder;
// }
// .placeholder-inverted {
//   background-color: $color-image-placeholder-inverted;
// }

// .lazy,
// .lazyload {
//   opacity: 0;
//   transition: opacity .3s;
// }
// .lazyloaded {
//   opacity: 1;
// }

// input:-webkit-autofill,
// input:-webkit-autofill:hover,
// input:-webkit-autofill:focus,
// textarea:-webkit-autofill,
// textarea:-webkit-autofill:hover
// textarea:-webkit-autofill:focus {
//   -webkit-transition: color 9999s ease-out, background-color 9999s ease-out;
//   -webkit-transition-delay: 9999s;
//   -webkit-box-shadow: 0 0 0px 1000px rgba(color("gold-tan"), 0.05) inset;
// }

// #main {
//   position: absolute; // because otherwise in ms edge opacity not works on #main!
// }
