.Logo {
  display: block;
  width: auto;
  height: 42px;
  @include media-breakpoint-up(sm) {
    height: px2vw(42, 1024);
    min-height: 42px;
    max-height: 64px;
  }
}

.Logo-mark {
  fill: color("red");
}

.Logo-type {
  fill: color("gray");
}
