.Partners {
  list-style-type: none;
  display: inline;
  flex-wrap: wrap;
  li {
    display: inline-block;
    margin-right: 0.25em;
    &:first-child {
      margin-left: 0.25em;
    }
  }
}
